<template>
    <div>
        <v-toolbar flat>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>arrow_back</v-icon>
            </v-btn>
            <v-toolbar-title>{{ item.name }}</v-toolbar-title>
        </v-toolbar>
        <el-container>
            <el-main>
                <v-row justify="start">
                    <v-col cols="12" sm="12" md="6" id="modules-column">
                        <h3 class="my-2">Modules <el-button id="expand-close" size="mini" @click="all()"
                                v-if="panel.length < filterOrgModules.length">
                                <v-icon small>add</v-icon>Expand All
                            </el-button>
                            <el-button id="expand-close" size="mini" @click="none()" v-if="panel.length > 0">
                                <v-icon small>remove</v-icon> Close All
                            </el-button>
                        </h3>
                        <v-expansion-panels v-model="panel" multiple :key="key">
                            <!-- Modules -->
                            <v-expansion-panel v-for="(systemModule) in filterOrgModules" :key="systemModule.id">
                                <v-expansion-panel-header expand-icon="mdi-menu-down">{{ systemModule.name
                                }}</v-expansion-panel-header>
                                <!-- Module Components -->
                                <v-expansion-panel-content>
                                    <v-expansion-panels accordion>
                                        <v-expansion-panel v-for="route in systemModule.routes" :key="route.id"
                                            :readonly="route.route.routeTabs.length == 0">
                                            <v-expansion-panel-header expand-icon="mdi-menu-down"
                                                :hide-actions="route.route.routeTabs.length == 0">
                                                <v-row>
                                                    <v-col cols="12" sm="12" md="6">
                                                        {{ route.route.name }}
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="6" class="text-center">
                                                        <v-btn-toggle id="perms-toggle"
                                                            v-if="route.route.routeTabs.length == 0" dense text rounded
                                                            v-model="route.access">
                                                            <v-tooltip left>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn v-bind="attrs" v-on="on" outlined
                                                                        @click="route.access = 'none', createRoleAccess(null, route.route.id, 'none')"
                                                                        value="none" small
                                                                        :color="route.access == 'none' ? 'red' : 'grey'">
                                                                        <v-icon small
                                                                            :color="route.access == 'none' ? 'red' : 'grey'">block</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span style="font-size: 12px">No Access</span>
                                                            </v-tooltip>
                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn v-bind="attrs" v-on="on" outlined
                                                                        @click="route.access = 'read', createRoleAccess(null, route.route.id, 'read')"
                                                                        value="read" small
                                                                        :color="route.access == 'read' ? 'blue' : 'grey'">
                                                                        <v-icon small
                                                                            :color="route.access == 'read' ? 'blue' : 'grey'">visibility</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span style="font-size: 12px">Read Only</span>
                                                            </v-tooltip>
                                                            <v-tooltip right>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn v-bind="attrs" v-on="on" outlined
                                                                        @click="route.access = 'write', createRoleAccess(null, route.route.id, 'write')"
                                                                        value="write" small
                                                                        :color="route.access == 'write' ? 'green' : 'grey'">
                                                                        <v-icon small
                                                                            :color="route.access == 'write' ? 'green' : 'grey'">check_circle</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span style="font-size: 12px">Read/Write</span>
                                                            </v-tooltip>
                                                        </v-btn-toggle>
                                                    </v-col>

                                                </v-row>

                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-list dense class="my-0 py-0">
                                                    <v-list-item tile dense style="border-bottom: 1px solid lightgrey"
                                                        v-for="tab in route.route.routeTabs" :key="tab.id">
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ tab.name }}</v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-list-item-action>
                                                            <v-btn-toggle dense text rounded v-model="tab.access">
                                                                <v-tooltip left>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn v-bind="attrs" v-on="on" outlined
                                                                            @click="tab.access = 'none', createRoleAccess(tab.id, route.route.id, 'none')"
                                                                            value="none" small
                                                                            :color="tab.access == 'none' ? 'red' : 'grey'">
                                                                            <v-icon small
                                                                                :color="tab.access == 'none' ? 'red' : 'grey'">block</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span style="font-size: 12px">No Access</span>
                                                                </v-tooltip>
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn v-bind="attrs" v-on="on" outlined
                                                                            @click="tab.access = 'read', createRoleAccess(tab.id, route.route.id, 'read')"
                                                                            value="read" small
                                                                            :color="tab.access == 'read' ? 'blue' : 'grey'">
                                                                            <v-icon small
                                                                                :color="tab.access == 'read' ? 'blue' : 'grey'">visibility</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span style="font-size: 12px">Read Only</span>
                                                                </v-tooltip>
                                                                <v-tooltip right>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn v-bind="attrs" v-on="on" outlined
                                                                            @click="tab.access = 'write', createRoleAccess(tab.id, route.route.id, 'write')"
                                                                            value="write" small
                                                                            :color="tab.access == 'write' ? 'green' : 'grey'">
                                                                            <v-icon small
                                                                                :color="tab.access == 'write' ? 'green' : 'grey'">check_circle</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span style="font-size: 12px">Read/Write</span>
                                                                </v-tooltip>
                                                            </v-btn-toggle>
                                                        </v-list-item-action>
                                                    </v-list-item>
                                                </v-list>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>

                    <v-col cols="12" sm="12" md="6">
                        <h3>Users <v-btn class="ml-1" icon @click="userDialog = true">
                                <v-icon>add</v-icon>
                            </v-btn>
                        </h3>
                        <v-data-table class="mt-2" :items="roleUsers" :headers="headers" hide-default-footer>
                            <template v-slot:item.action="{ item }">
                                <v-btn icon color="red" @click="removeUser(item)">
                                    <v-icon>remove</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-col>

                </v-row>
            </el-main>
        </el-container>

        <!-- Add users dialog -->
        <el-dialog :title="'Add Users to ' + item.name + ' role'" :visible.sync="userDialog"
            :fullscreen="$vuetify.breakpoint.mobile" width="850px" :closed="clearUsers">
            <v-row justify="end">
                <v-col cols="12" sm="12" md="8" lg="6">
                    <el-input suffix-icon="el-icon-search" v-model="userSearch" clearable placeholder="Search"
                        @keyup.enter.native="getUsers()">
                        <template slot="prepend" v-if="tempUsers.length > 0">
                            {{ tempUsers.length }} selected
                        </template>

                    </el-input>
                </v-col>
            </v-row>
            <el-container>
                <el-main>
                    <v-data-table class="mt-2" item-key="userId" v-model="tempUsers" show-select :items="filteredUsers"
                        :headers="selectHeaders" :search="userSearch">
                    </v-data-table>
                </el-main>
                <el-footer>
                    <el-row justify="center" type="flex">
                        <el-button type="primary" :disabled="tempUsers.length == 0"
                            @click="addUsersToRole()">Save</el-button>
                    </el-row>
                </el-footer>
            </el-container>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data: () => ({
        panel: [],
        headers: [{
            value: 'action',
            text: 'Action',
            align: 'center',
            sortable: false
        },
        {
            value: 'user.firstname',
            text: 'Name',
            align: 'center'
        },
        {
            value: 'user.surname',
            text: 'Surname',
            align: 'center'
        },
        {
            value: 'user.emailAddress',
            text: 'Email',
            align: 'center'
        },
        {
            value: 'position',
            text: 'Position',
            align: 'center'
        }
        ],
        selectHeaders: [{
            value: 'user.firstname',
            text: 'Name',
            align: 'center'
        },
        {
            value: 'user.surname',
            text: 'Surname',
            align: 'center'
        },
        {
            value: 'user.emailAddress',
            text: 'Email',
            align: 'center'
        },
        {
            value: 'position',
            text: 'Position',
            align: 'center'
        }
        ],
        loading: false,
        key: 0,
        item: {},
        moduleSearch: '',
        orgModules: [],
        orgUsers: [],
        roleUsers: [],
        roleAccess: [],
        tempUsers: [],
        userSearch: '',
        userDialog: false,
    }),
    watch: {
        '$route.params.id': {
            immediate: true,
            handler(value) {
                if (value) {
                    this.loadRole(value)
                }
            }
        }
    },
    computed: {
        filteredUsers() {
            let result = this.orgUsers
            result = result.filter(x => !this.roleUsers.map(y => y.userId).includes(x.userId))
            return result
        },
        filterOrgModules() {
            let result = this.orgModules
            if (this.moduleSearch) {
                result = result.filter(x =>
                    (x.systemModule.name.toLowerCase()).includes(this.moduleSearch.toLowerCase())
                )
            }
            return result
        }
    },
    methods: {
        async addUsersToRole() {
            let roleArray = this.tempUsers.map(x => ({
                roleId: this.item.id,
                userId: x.userId
            }))
            await this.$API.createUserRole({
                batch: roleArray
            })
            this.getOrganisationUsers()
            this.clearUsers()
        },
        all() {
            this.panel = this.filterOrgModules.map((x, index) => index)
            this.key++
        },
        clearUsers() {
            this.tempUsers = []
            this.userDialog = false
        },
        // Reset the panel
        none() {
            this.panel = []
        },
        checkAccess(tab) {
            let find = this.roleAccess.find(x => x.routeTabId == tab.id)
            return find ? find.access : 'none'
        },
        checkRouteAccess(route) {
            let find = this.roleAccess.find(x => x.routeId == route.id && x.routeTabId == null)
            return find ? find.access : 'none'
        },
        async createRoleAccess(id, routeId, access) {
            await this.$API.createRoleAccess({
                roleId: this.item.id,
                routeId: routeId,
                routeTabId: id,
                access: access
            })
        },
        async loadRole(id) {
            this.item = await this.$API.getRoleDetails(id)
            await this.getRoleAccess(this.item.id)
            this.getOrganisationModules(this.item.organisationId)
            this.getOrganisationUsers()
        },
        async getOrganisationUsers() {
            this.loading = true
            let users = await this.$API.getOrganisationUsers()
            this.orgUsers = users
            this.roleUsers = this.orgUsers.filter(x => x.user.userRoles.map(y => y.roleId).includes(this.item.id))
            this.loading = false
        },
        async getOrganisationModules(id) {
            let data = await this.$API.getOrganisationModuleAdmin(id)
            console.log(data)
            // data = data.data
            for (let i = 0; i < data.length; i++) {
                data[i].routes = await this.$API.getModuleRoute(data[i].id)
                for (let j = 0; j < data[i].routes.length; j++) {

                    if (data[i].routes[j].route.routeTabs.length == 0) {
                        data[i].routes[j].access = this.checkRouteAccess(data[i].routes[j].route)

                    }
                    for (let k = 0; k < data[i].routes[j].route.routeTabs.length; k++) {
                        data[i].routes[j].route.routeTabs[k].access = this.checkAccess(data[i].routes[j].route.routeTabs[k])
                    }
                }

            }
            this.orgModules = data
            this.all()

        },
        async getRoleAccess(id) {
            this.roleAccess = await this.$API.getRoleAccess(id)
        },
        async removeUser(user) {
            let role = user.user.userRoles.find(x => x.roleId == this.item.id)
            await this.$API.createUserRole({
                batch: [{ id: role.id, isActive: false, isDeleted: true }]
            })
            this.getOrganisationUsers()
        },
        userSelectionChange(data) {
            this.tempUsers = data
        },
    }
}
</script>
